<template>
  <div id="mian">
    <div class="header">
      <span @click="navClick('终端配置')" :class="crumbs == '终端配置' ? 'active' : 'left'">终端配置</span>
      <span @click="navClick('其他配置')" :class="crumbs == '其他配置' ? 'active' : 'left'">其他配置</span>
    </div>
    <!-- 终端配置 -->
    <div v-if="crumbs == '终端配置'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>终端名称</i>
            <el-input
              v-model="query.termKeyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="终端名称/终端编号"
            ></el-input>
          </div>
          <div class="item">
            <i>门店名称</i>
            <el-input
              v-model="query.storeKeyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="门店名称/门店编号"
            ></el-input>
          </div>
          <div class="item">
            <i>分账商户</i>
            <el-input
              v-model="query.allocKeyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="分账商户/编号"
            ></el-input>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="v in mchDrop"
                :key="v.mchId"
                :label="v.mchName"
                :value="v.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item itemTime">
            <i>创建时间</i>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn" style="width:12%;">
            <el-button @click="getLists" type="primary" class="searchBt">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
            </td>
            <td>
              <p>终端名称</p>
              <p>终端编号</p>
            </td>
            <td>
              <p>门店名称</p>
              <p>门店编号</p>
            </td>
            <td>
              <p>分账商户</p>
              <p>商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>分账比例</p>
            </td>
            <td>
              <p>终端小程序</p>
              <p>权限</p>
            </td>
            <td>
              <p>退款免审</p>
              <p>模式</p>
            </td>
            <td>
              <p>手工结算</p>
              <p>免审模式</p>
            </td>
            <td>
              <p>修改账户</p>
              <p>权限</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <div>{{ v.createTime ? v.createTime : "" }}</div>
            </td>
            <td>
              <div>{{v.opTermName}}</div>
              <div>{{v.opTermNo }}</div>
            </td>
            <td>
              <div>{{ v.storeName }}</div>
              <div>{{ v.storeId }}</div>
            </td>
            <td>
              <div>{{ v.allocMchName}}</div>
              <div>{{ v.allocMchId }}</div>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <div>{{ v.accountRatio != "" ? v.accountRatio / 100 + "%" : "/" }}</div>
            </td>
            <td>
              <p v-show="ZDXCXQX != 'ACCOUNT:TERMINAL:CiF/ZDXCXQX'">{{ v.allocWxAppStatus==true ? "已开启" : "已关闭" }}</p>
              <p style="width: 50px;margin: auto;" v-auth="'ACCOUNT:TERMINAL:CiF/ZDXCXQX'">
                  <el-switch
                    :value="v.allocWxAppStatus == null ? false : v.allocWxAppStatus"
                    @input="setAuthConfig(1, {allocWxAppStatus: v.allocWxAppStatus,allocMchId:v.allocMchId}) "
                    active-color="#13ce66"
                  ></el-switch>
                </p>
            </td>
            <td>
              <p v-show="TKMSMS != 'ACCOUNT:TERMINAL:CiF/TKMSMS'"> {{ v.allocRefundStatus==true ? "已开启" : "已关闭" }}</p>
              <p style="width: 50px;margin: auto;" v-auth="'ACCOUNT:TERMINAL:CiF/TKMSMS'">
                  <el-switch
                     :value="v.allocRefundStatus == null ? false : v.allocRefundStatus"
                    @input="setAuthConfig(2, {allocRefundStatus: v.allocRefundStatus,allocMchId:v.allocMchId})"
                    active-color="#13ce66"
                  ></el-switch>
                </p>        
            </td>
            <td>
              <p v-show="SGJSMSMS != 'ACCOUNT:TERMINAL:CiF/SGJSMSMS'"> {{ v.allocSettleStatus==true ? "已开启" : "已关闭" }}</p>
              <p style="width: 50px;margin: auto;" v-auth="'ACCOUNT:TERMINAL:CiF/SGJSMSMS'">
                  <el-switch
                     :value="v.allocSettleStatus == null ? false : v.allocSettleStatus "
                    @input="setAuthConfig(3, {allocSettleStatus: v.allocSettleStatus,allocMchId:v.allocMchId})"
                    active-color="#13ce66"
                  ></el-switch>
                </p>
            </td>
            <td>
              <p v-show="XGZHQX != 'ACCOUNT:TERMINAL:CiF/XGZHQX'"> {{ v.allocAccountStatus==true ? "已开启" : "已关闭" }}</p>
              <p style="width: 50px;margin: auto;" v-auth="'ACCOUNT:TERMINAL:CiF/XGZHQX'">
                  <el-switch
                      :value="v.allocAccountStatus == null ? false : v.allocAccountStatus"
                    @input="setAuthConfig(4, {allocAccountStatus: v.allocAccountStatus,allocMchId:v.allocMchId}) "
                    active-color="#13ce66"
                  ></el-switch>
                </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>

    <!-- 其他配置 -->
    <div v-if="crumbs == '其他配置'">
        <div class="others">
            <div class="desc">
              <h5 class="jsxx">配置信息</h5>
              <el-button @click="otherInfoEdit" class="editBt" type="primary" v-auth="'ACCOUNT:TERMINAL:CiF/EDIT'">编辑</el-button>
            </div>
            <div class="list clear">
              <ul class="listBox">
                <li>
                  <span>企业二维码</span>
                  <i class="hidden1">
                    <a href="javascript:;"
                      @click="showImg(otherInfo.allocMchPicture)"
                      > {{ otherInfo.allocMchPicture == "" ? "" : "点击预览"}}</a
                    >
                  </i>
                </li>
              </ul>
              <div class="right_con">

              </div>
            </div>
        </div>
    </div>
    <!-- 预览 -->
    <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
        <a :href="showImgUrl" target="_blank">
          <img :src="showImgUrl" alt="加载失败" />
        </a>
    </div> 
    <!-- 编辑 -->
    <el-dialog title="其他配置" :visible.sync="otherEidtShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">企业二维码</div>
          <div class="hidden1" style="height:auto;">
            <el-upload
              class="avatar-uploader"
              :before-upload="beforeUpload"
              drag
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="enterpriseQRcodeSuccess"
            >
              <img
                v-if="allocMchPicture"
                :src="allocMchPicture"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="allocMchPicture" class="avatar-uploader-span" @click.stop="allocMchPicture = ''"><i class="el-icon-close"></i></span>
            </el-upload>
          </div>
        </li>
        <li class="ul-right">

        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="otherEidtShow = false">取 消</el-button>
        <el-button class="addBt" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { merchantsDrop } from "@/api/common.js";
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import { alloctreminals , settreminalconfigStatus , otherconfigPicture , getOtherconfigInfo } from "@/api/user/applet.js";
import * as imageConversion from "image-conversion";
export default {
  components: {},
  data() {
    return {
      query: {
        startTime: "",//formatDate(+new Date(), "yyyy-MM-dd 00:00:00")
        endTime: "",//formatDate(+new Date(), "yyyy-MM-dd 23:59:59")
        pageNo: 1,
        pageSize: 10,
        mchId:"",//所属商户
        termKeyword:"",// 终端名称/终端编号
        storeKeyword:"", //门店名称/门店编号
        allocKeyword:"",// 分账商户名称/编号
      },
      totalNum: 0, //数据总条数
      tabData: [],
      crumbs: "终端配置",
      mchDrop: [], //所属商户下拉
      otherEidtShow:false,
      otherInfo:{
        allocMchPicture:"", // 企业微信
      },
      allocMchPicture:"",
      uploadUrl: URL_API.upload,
      isImge: true, //判断上传的是不是图片
      showImgUrl:"",
      ZDXCXQX:"ACCOUNT:TERMINAL:CiF/ZDXCXQX",
      TKMSMS:'ACCOUNT:TERMINAL:CiF/TKMSMS',
      SGJSMSMS:'ACCOUNT:TERMINAL:CiF/SGJSMSMS',
      XGZHQX:'ACCOUNT:TERMINAL:CiF/XGZHQX',
    };
  },
  computed: {
    ...mapState({
      userTerminalConfig: "user_terminalConfig",
    }),
  },
  created() {
    if (this.userTerminalConfig) {
      this.query = this.userTerminalConfig.query;
    }
    this.getDrop();
    this.getLists(false);
    this.listJurisdiction();
    this._getOtherconfigInfo();
  },
  methods: {
    ...mapMutations({
      setUserTerminalConfig: "user_setTerminalConfig",
    }),

    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "终端配置") {
        this.getLists();
      } else {
        this._getOtherconfigInfo();
      }
    },
    // 获取所属商户下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res.resultStatus) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      alloctreminals(this.query).then((res) => {
        if (res.resultStatus) {

          this.tabData = res.resultData.mchAllocTreminals;
          this.totalNum = res.resultData.totalNum;

          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }         
        }
      });
    },

    //表格权限操作权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources
        if(_resources=='ALL'){
            this.ZDXCXQX = 'ACCOUNT:TERMINAL:CiF/ZDXCXQX';
            this.TKMSMS = 'ACCOUNT:TERMINAL:CiF/TKMSMS';
            this.SGJSMSMS = 'ACCOUNT:TERMINAL:CiF/SGJSMSMS';
            this.XGZHQX = 'ACCOUNT:TERMINAL:CiF/XGZHQX';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:TERMINAL:CiF/ZDXCXQX') !== -1 ){
            this.ZDXCXQX = 'ACCOUNT:TERMINAL:CiF/ZDXCXQX';
          }else{
            this.ZDXCXQX = '';
          }
          if(_resources.split(';').indexOf('ACCOUNT:TERMINAL:CiF/TKMSMS') !== -1 ){
            this.TKMSMS = 'ACCOUNT:TERMINAL:CiF/TKMSMS';
          }else{
            this.TKMSMS = '';
          }
          if(_resources.split(';').indexOf('ACCOUNT:TERMINAL:CiF/SGJSMSMS') !== -1 ){
            this.SGJSMSMS = 'ACCOUNT:TERMINAL:CiF/SGJSMSMS';
          }else{
            this.SGJSMSMS = '';
          }
          if(_resources.split(';').indexOf('ACCOUNT:TERMINAL:CiF/XGZHQX') !== -1 ){
            this.XGZHQX = 'ACCOUNT:TERMINAL:CiF/XGZHQX';
          }else{
            this.XGZHQX = '';
          }
        }
    },
    
    // 详情
    showTab(v) {
      this.$router.push({
        name: "terminal_config_detail",
        query: {
          id: v.opTermNo,
          pageType: "edit",
        },
      });
    },

    setAuthConfig(type, value) {
      const statusType = Object.keys(value)[0].toString();
      const allocMchId = value.allocMchId;
      // console.log(statusType,"----------------",allocMchId)
      settreminalconfigStatus(allocMchId, statusType).then((res) => {
          if(res.resultStatus == true){ 
            if (statusType == 'allocWxAppStatus') {
                this.tabData.forEach((e) => {
                  if (e.allocMchId == allocMchId && e.allocWxAppStatus == false) {
                    e.allocWxAppStatus = true;
                  } else if((e.allocMchId == allocMchId && e.allocWxAppStatus == true)) {
                    e.allocWxAppStatus = false;
                  }
                });
            }
            if (statusType == 'allocRefundStatus') {
                this.tabData.forEach((e) => {
                  if (e.allocMchId == allocMchId && e.allocRefundStatus == false) {
                    e.allocRefundStatus = true;
                  } else if((e.allocMchId == allocMchId && e.allocRefundStatus == true)) {
                    e.allocRefundStatus = false;
                  }
                });
            }
            if (statusType == 'allocSettleStatus') {
                this.tabData.forEach((e) => {
                  if (e.allocMchId == allocMchId && e.allocSettleStatus == false) {
                    e.allocSettleStatus = true;
                  } else if(e.allocMchId == allocMchId && e.allocSettleStatus == true) {
                    e.allocSettleStatus = false;
                  }
                });
            }
            if (statusType == 'allocAccountStatus') {
                this.tabData.forEach((e) => {
                  if (e.allocMchId == allocMchId &&  e.allocAccountStatus == false) {
                    e.allocAccountStatus = true;
                  } else if(e.allocMchId == allocMchId &&  e.allocAccountStatus == true) {
                    e.allocAccountStatus = false;
                  }
                });
            }
          }
          
      });
    },

    //其他信息-获取
    _getOtherconfigInfo(){
      getOtherconfigInfo().then((res) => {
         if (res.resultStatus) {
          this.otherInfo = res.resultData;
        }
      })
    },

    //其他信息-编辑
    otherInfoEdit(){
      this.allocMchPicture = this.otherInfo.allocMchPicture;
      this.otherEidtShow = true;
    },

    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },

    //其他信息 编辑 确定
    submit() {
      this.otherInfo.allocMchPicture = this.allocMchPicture;
      otherconfigPicture(this.otherInfo).then((res) => {
        if (res.resultStatus) {
          console.log(res)
          // this.$message.success("保存成功");
          // this.getDetail();
          this.otherEidtShow = false;
        }
      });
    },
    enterpriseQRcodeSuccess(res) {
      if (this.isImge && res.resultStatus) {
        console.log(res.resultData.fileLink)
        this.allocMchPicture = res.resultData.fileLink;
      }else{
        return false
      }
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      console.log("文件上传前", extension);

      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return;
      }
      // if (!extension) {
      //   return false;
      // }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },

    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setUserTerminalConfig(data);
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";

.tab tr td p , .tab tr td div{
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.overAccount_ul {
  overflow: hidden;
}
.overAccount_ul .hidden1 {
  height: 19px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
.dialog-footer .addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
</style>
